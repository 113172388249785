.cards-wrapper {
  margin: 90px auto 20px;
}

.cards-wrapper .ant-row {
  display: flex;
  justify-content: center;
}

.ant-col {
  width: 25%;
  box-sizing: border-box;
  margin: 20px 15px;
}

.ant-card {
  width: 100%;
}

.detail-wrapper {
  max-width: 600px;
  margin: 90px auto 20px;
  background-color: white;
}

.ant-card-cover img {
  height: 200px;
  object-fit: cover;
}

.detail-wrapper img {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.ant-card-meta-title {
  font-weight: 700;
  font-size: 1.5rem;
}

.car-detail-wrapper {
  padding: 30px;
}

@media screen and (max-width: 580px) {
  .ant-col {
    width: 100%;
  };
  .new-car-form{
    width: 100%
  }
}

@media screen and (min-width: 581px) {
  .ant-col {
    width: 40%;
  }
  .ant-card-meta-title {
    font-size: 1.25rem;
  };
  .new-car-form{
    width: 100%
  }
}

@media screen and (min-width: 780px) {
  .ant-col {
    width: 29%;
  };
  .new-car-form{
    width: 100%
  }
  
}

.new-car-form{
    max-width: 800px;
    margin: 90px auto 20px;
    background-color: white;
}

.submit-rating {
  display: flex;
  flex-direction: column;
}
.submit-form {
  display: flex;
  flex-direction: column;
}

@media all and (min-width: 250px) {
.header-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
  position: fixed !important;
  z-index: 999 !important;
  width: 100%;
  background-color: orange !important;
  opacity: 85% !important;
}

.signin-login {
  width: 300px;
  border: 1px solid gray;
  padding: 50px;
  margin: 20px;

}

.signin-login-text {
  color: blue;
  text-align: center;
}
}

.flex-form {
    padding: 30px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}