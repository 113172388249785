
.wrapper {
    height: 97vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: aliceblue;
  }
  
  .form-wrapper {
    display: flex;
    flex-direction: column;
    width: 280px;
    max-width: 80%;
    min-width: 100px;
    min-height: 400px;
    padding: 20px 40px;
    border-radius: 6px;
    box-shadow: 0px 2px 6px #222;
    background-color: #fefefe;
  }
  
  .form-wrapper > h2 {
    display: flex;
    justify-content: center;
    font-family: 'Segoe UI', 'Ubuntu', 'Roboto', 'Open Sans', 'Helvetica Neue',
      sans-serif;
    font-size: 2em;
    font-weight: lighter;
    margin-top: 0.25em;
    color: #222;
  }
  
  .login-sign-in-form {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
  
  .info {
    padding-bottom: 1em;
    padding-left: 0.5em;
    padding-right: 0.5em;
  }
  
  .label-signin-login {
    margin-bottom: 0.5em;
    color: #444;
    font-weight: lighter;
  }
  
  .input-signin-login {
    padding: 10px 10px;
    border-radius: 5px;
    outline: none;
    border: 1px solid #d6d1d5;
  }
  
  input::placeholder {
    font-size: 1.2em;
    font-weight: lighter;
    color: #bbb;
  }
  
  .button-signin-login {
    min-width: 100%;
    cursor: pointer;
    margin-right: 0.25em;
    margin-top: 0.5em;
    padding: 0.938em;
    border: none;
    border-radius: 4px;
    background-color: orange;
    color: #fefefe;
  }
  button:hover {
    background-color: blue;
    color: #fefefe;
  }
  
  .error {
    color: #db2269;
    font-size: 0.5em;
    display: relative;
  }
  
  .fullName,
  .email,
  .password {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
  }
  
  .fullName {
    width: 100%;
  }
  
  .email,
  .password {
    width: 100%;
  }
  
  .submit {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
  